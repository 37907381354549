@font-face {
    font-family: 'Bw Modelica SS02 Black';
    src: url('res/fonts/BwModelicaSS02-Black.woff.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed', sans-serif;
    src: url('res/fonts/RobotoCondensed-Regular.ttf');
    font-display: swap;
}

h1 {
    font-family: 'Bw Modelica SS02 Black';
    font-size:24px;
}

b {
    /*font-family: 'Bw Modelica SS02 Black';*/
    /*color: #256782;*/
}
.title{
    color:#e5011e;
}